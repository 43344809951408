import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Palette, PersonalVideo, Work } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/mainPageSections/briefStyle.js";

const useStyles = makeStyles(styles);

export default function BriefSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>World Class iOS Developer</h2>
          <h5 className={classes.description}>
          Collaborated with other engineers to develop several major applications for large companies,
          utilizing robust technology stacks and automations to enhance the quality of life for engineers and other stakeholders.
          As a graduate of the Apple Developer Academy, I strive to create solutions that help people with their daily tasks
          by using methods such as Challenge-Based Learning (CBL) to identify and address issues in various situations.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <InfoArea
              title="Traveloka"
              description="
              Worked on Accommodation and Eats sub products. Delivered many high level projects such as revamping main product flow from obj-c to swift.
              Helped identify problems in existing codebase and provide solutions to it by introducing tech stacks and best practices.
              Maintained Unit Test and UI Test of the products so that it reaches high coverage.
              "
              icon={PersonalVideo}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <InfoArea
              title="Mandiri"
              description="
              Worked on the new livin m-banking application by utilising robust architecture and tech pattern to help create maintainable and reusable code.
              Collaborated with multiple engineers from multiple countries and making sure all stakeholders were well informed about ongoing tasks.
              Provided Proof Of Concept (POC) to the stakeholders of things that can be improved in the app.
              "
              icon={Work}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <InfoArea
              title="Apple Academy"
              description="
              Collaborated with multiple people with different background and interests to create applications that potentially solve real world problems using Challenge Based Learning (CBL) framework.
              Designed several applications created during academy such as logo, storyboard, and application interfaces.
              Learned to create persona(s) for ongoing projects and identify issues / solution through interviewing and tabulating datas.
              "
              icon={Palette}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
