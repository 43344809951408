import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";

// @material-ui/icons
import {
  Contacts,
  Instagram,
  Phone,
  Mail,
  Language,
  Facebook,
  GitHub,
  LinkedIn,
  Twitter,
  Gamepad,
  WhatsApp,
} from "@material-ui/icons";
// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/me.jpg";

import styles from "assets/jss/material-kit-react/views/profileCardPage.js";

const useStyles = makeStyles(styles);

export default function ProfileCardPage() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div>
      <Parallax small filter image={require("assets/img/bg.png")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>William</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}></div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={5} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Contact",
                      tabIcon: Contacts,
                      tabContent: (
                        <GridContainer justify="center">
                          <List className={classes.list}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                  <Phone />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary="Phone"
                                secondary="+62 882-8933-7968"
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                  <Mail />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary="E-mail"
                                secondary="william.inxcorp@gmail.com"
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <a href="/" className={classes.list}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                    <Language />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary="Website"
                                  secondary="www.inxudianz.com"
                                />
                              </ListItem>
                            </a>
                          </List>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Social",
                      tabIcon: Instagram,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.navWrapper}
                          >
                            <List className={classes.list}>
                              <a
                                href="https://www.instagram.com/inxudianz"
                                className={classes.list}
                              >
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                      <Instagram />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary="Instagram"
                                    secondary="@Inxudianz"
                                  />
                                </ListItem>
                              </a>
                              <a
                                href="https://www.linkedin.com/in/william-inxudianz/"
                                className={classes.list}
                              >
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                      <LinkedIn />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary="LinkedIn"
                                    secondary="@william-inxudianz"
                                  />
                                </ListItem>
                              </a>
                              <a
                                href="https://github.com/inxudianz"
                                className={classes.list}
                              >
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                      <GitHub />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary="Github"
                                    secondary="www.inxudianz.com"
                                  />
                                </ListItem>
                              </a>
                              <a
                                href="https://api.whatsapp.com/send?phone=6288289337968"
                                className={classes.list}
                              >
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                      <WhatsApp />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary="WhatsApp"
                                    secondary="+6288289337968"
                                  />
                                </ListItem>
                              </a>
                            </List>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.navWrapper}
                          >
                            <a
                              href="https://www.facebook.com/inxudianz/"
                              className={classes.list}
                            >
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                    <Facebook />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary="Facebook"
                                  secondary="@Inxudianz"
                                />
                              </ListItem>
                            </a>
                            <a
                              href="https://twitter.com/william_scs2"
                              className={classes.list}
                            >
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                    <Twitter />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary="Twitter"
                                  secondary="@william_scs2"
                                />
                              </ListItem>
                            </a>
                            <a
                              href="https://steamcommunity.com/id/inxudianz"
                              className={classes.list}
                            >
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                    <Gamepad />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary="Steam"
                                  secondary="Inxudianz"
                                />
                              </ListItem>
                            </a>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
