import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { Extension, Language } from "@material-ui/icons";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/me.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div>
      <Header
        color="transparent"
        brand="Inxudianz"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg.png")}>
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>William</h3>
                    <h6>iOS Developer</h6>
                    <Button
                      justIcon
                      link
                      className={classes.margin5}
                      href="https://api.whatsapp.com/send?phone=6288289337968"
                      target="_blank"
                    >
                      <i className={"fab fa-whatsapp"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      className={classes.margin5}
                      href="https://github.com/inxudianz"
                      target="_blank"
                    >
                      <i className={"fab fa-github"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      className={classes.margin5}
                      href="https://www.instagram.com/inxudianz/"
                      target="_blank"
                    >
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      className={classes.margin5}
                      href="https://www.linkedin.com/in/william-inxudianz/"
                      target="_blank"
                    >
                      <i className={"fab fa-linkedin"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Passionate world class developer that aims to help people with
                their daily task easier through technology. I
                specialise in iOS development and have created / contributed in several high profile iOS apps.
                I love problem solving and aims to provide quality products that
                help people in their daily life.
              </p>
              <p>
                I&apos;m currently working as an iOS Developer in Grab.
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Experiences",
                      tabIcon: Language,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                            <h4 className={classes.description}>
                              <b>Grab</b>
                            </h4>
                            <h6 className={classes.description}>
                              Oct 2024 - Present
                            </h6>
                            <p className={classes.description}>iOS Developer</p>
                            <p className={classes.description}>
                            </p>
                            <h3 className={classes.title}>.</h3>
                            <h4 className={classes.description}>
                              <b>Bank Mandiri</b>
                            </h4>
                            <h6 className={classes.description}>
                              May 2020 - April 2021
                            </h6>
                            <p className={classes.description}>iOS Developer</p>
                            <p className={classes.description}>
                              Collaborated with assigned team to create features
                              and fixing bugs for livin mandiri app.
                            </p>
                            <h3 className={classes.title}>.</h3>
                            <h4 className={classes.description}>
                              <b>KoinWorks</b>
                            </h4>
                            <h6 className={classes.description}>
                              February 2020 - April 2020
                            </h6>
                            <p className={classes.description}>iOS Developer</p>
                            <p className={classes.description}>
                              Improving codebase and fixing bugs for koinworks application.
                            </p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <h4 className={classes.description}>
                              <b>Traveloka</b>
                            </h4>
                            <h6 className={classes.description}>
                              May 2021 - Oct 2024
                            </h6>
                            <p className={classes.description}>iOS Developer</p>
                            <p className={classes.description}>
                              Assigned as <b>SwiftUI Panelist</b> for researching and pioneering swiftUI in Traveloka codebases.
                            </p>
                            <p className={classes.description}>
                              Volunteered as <b>iOS Evangelist</b> briding infra team and product team and also maintaining team's code qualities.
                            </p>
                            <p className={classes.description}>
                              Becoming <b>Release Engineer</b> with tasks to review and merge fixes / urgent request for release branches.
                            </p>
                            <p className={classes.description}>
                              Worked on Accommodation and Eats team on high complexity projects.
                              Maintained Unit Tests and UI Tests to make sure coverage were above the threshold.
                              Migrated and revamped legacy code from obj-c to swift.
                            </p>
                            <h3 className={classes.title}>.</h3>
                            <h4 className={classes.description}>
                              <b>Apple Developer Academy</b>
                            </h4>
                            <h6 className={classes.description}>
                              March 2019 - February 2020
                            </h6>
                            <p className={classes.description}>
                              Junior Developer
                            </p>
                            <p className={classes.description}>
                              Created 5 iOS application using Challenge Based
                              Learning (CBL) framework that can contribute to
                              the community.
                              Interviewed multiple HR's to help create an app that help fresh graduates through interview process.
                            </p>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Educations",
                      tabIcon: Extension,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={3} sm={3} md={1}></GridItem>
                          <GridItem xs={12} sm={12} md={5}>
                            <h4 className={classes.description}>
                              <b>Bina Nusantara University</b>
                            </h4>
                            <h6 className={classes.description}>2016-2020</h6>
                            <p className={classes.description}>
                              School of Computer Science
                            </p>
                            <p className={classes.description}>GPA 3.6</p>

                            <h3 className={classes.title}>.</h3>
                            <h4 className={classes.description}>
                              <b>
                                Apple Developer Academy iOS Foundation Program
                              </b>
                            </h4>
                            <h6 className={classes.description}>2018</h6>
                            <p className={classes.description}>Certificate</p>
                            <p className={classes.description}>
                              Trained for a month to study what is it like to be
                              a world class developer.
                            </p>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Skills",
                      tabIcon: Extension,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <h3 className={classes.title}>Main</h3>
                            <h4 className={classes.description}>
                              <b>Swift</b>
                            </h4>
                            <h4 className={classes.description}>
                              <b>Javascript</b>
                            </h4>
                            <h4 className={classes.description}>
                              <b>Java</b>
                            </h4>
                            <h4 className={classes.description}>
                              <b>C++</b>
                            </h4>
                            <h4 className={classes.description}>
                              <b>Python</b>
                            </h4>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <h3 className={classes.title}>Language</h3>
                            <h4 className={classes.description}>
                              <b>English</b>
                            </h4>
                            <p className={classes.description}>TOEFL 573</p>
                            <h4 className={classes.description}>
                              <b>Indonesia</b>
                            </h4>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
